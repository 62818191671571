import _ from 'lodash';

const fetchFracFleetMapComponentDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let fleetData = _.find(simData, {'id': fleetId});
      resolve({
        componentFleetMap: {
          componentViewData: {
            ...fleetData.componentSimData
          }
        }
      })
    }, 1000)
  });
};

const fetchFracFleetMapHealthDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let fleetData = _.find(simData, {'id': fleetId});
      resolve({
        healthFleetMap: {
          healthViewData: {
            ...fleetData.healthSimData
          }
        }
      })
    }, 1000)
  });
};

const fetchFracFleetMapOperationDetails = (fleetId) => {
  return new Promise((resolve) => {
    setTimeout(() => {
      let fleetData = simData[0];
      resolve({
        operationalFleetMap: {
          operationViewData: {
            ...fleetData.operationSimData
          }
        }
      })
    }, 1000)
  });
};

const sandCleanRate = _.random(3800, 9200); // gal/min 
const sandSelectedMassFlow = _.random(3800, 9200); // lbs/min (rate * conc = flow)
const sandMassConc = (sandSelectedMassFlow/sandCleanRate); // lbs/gal (flow / rate = conc)

const simData = [
  {
    id:6,
    name:'ETX-1',
    operationSimData: {
      blender1: { id:111, name: '720111',
        density: { value: 3, total: 10 },
        discharge: { value: 30, total: 300 },
        rate: { value: null, stopped: false, idle: true },
        tubAutoEnabled: true,
        tubAutoMode: true,
        alarmCounts: { critical: 0, warning: 0 }
      },
      blender2: { id:112, name: '720112',
        density: { value: 8, total: 10 },
        discharge: { value: 120, total: 300 },
        rate: { value: 52.8, stopped: false, idle: false },
        tubAutoEnabled: true,
        tubAutoMode: false,
        alarmCounts: { critical: 2, warning: 0 }
      },
      hydration: { id:113, name: '720113', alarmCounts: { critical: 0, warning: 0 } },
      chemvan: { id:114, name: '920114', alarmCounts: { critical: 0, warning: 15 } },
      datavan: { id:115, name: '720115',
        totalSand: 320046,
        totalDischarge: 4486,
        alarmCounts: { critical: 33, warning: 0 }
      },
      wellHead: { flowRate: 52.8, pressure: 9586, density1: 99.3, density2: 99.2 },
      sand: (_.random(1,20) % 7 === 0 ? null : {
        id: 101200, name: '720115',
        cleanRate: (_.random(1,20) % 5 === 0 ? null : sandCleanRate), 
        massConc: (_.random(1,20) % 5 === 0 ? null : sandMassConc), 
        selectedMassFlow: (_.random(1,20) % 5 === 0 ? null : sandSelectedMassFlow) 
      }),
      pumps: [
        { id: 513, name: '620263', slot: 1,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: true
        },
        { id: 538, name: '620254', slot: 2,
          suction: { value: 64, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 900,
          gear: '4',
          rate: { value: 3.2, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 55, warning: 0 },
          hasSand: false
        },
        { id: 660, name: '620521', slot: 3,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 200,
          gear: 'N',
          rate: { value: 5.3, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: true
        },
        { id: 350, name: '620484', slot: 4,
          suction: { value: 66, total: 100 },
          hhp: { value: 2750, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '5',
          rate: { value: 4.2, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 22 },
          hasSand: false
        },
        { id: 354, name: '620587', slot: 5,
          suction: { value: 65, total: 100 },
          hhp: { value: 600, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '2',
          rate: { value: 2.9, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 357, name: '620575', slot: 6,
          suction: { value: 63, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 150,
          gear: 'N',
          rate: { value: 5.3, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 122, name: '620122', slot: 7, empty: true },
        { id: 123, name: '620123', slot: 8, empty: true },
        { id: 358, name: '620505', slot: 9,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '3',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 359, name: '620393', slot: 10,
          suction: { value: 64, total: 100 },
          hhp: { value: 1900, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 360, name: '620496', slot: 11,
          suction: { value: 63, total: 100 },
          hhp: { value: 2145, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '5',
          rate: { value: 4.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 361, name: '620410', slot: 12,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '3',
          rate: { value: 3.2, tripped: false, idle: true, stopped: true },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 363, name: '620406', slot: 13,
          suction: { value: 65, total: 100 },
          hhp: { value: 1200, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 600,
          gear: '2',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 3, warning: 0 },
          hasSand: false
        },
        { id: 129, name: '620129', slot: 14, empty: true },
        { id: 396, name: '620477', slot: 15,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 0, total: 15000 },
          rpm: 0,
          gear: null,
          rate: { value: null, tripped: false, idle: false, stopped: true },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 418, name: '620588', slot: 16,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 400,
          gear: 'N',
          rate: { value: 4.0, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 132, name: '620132', slot: 17, empty: true },
        { id: 419, name: '620189', slot: 18,
          suction: { value: 65, total: 100 },
          hhp: { value: 725, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '2',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 420, name: '620188', slot: 19,
          suction: { value: 65, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 5.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 7 },
          hasSand: false
        },
        { id: 505, name: '620453', slot: 20,
          suction: { value: 64, total: 100 },
          hhp: { value: 2500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1300,
          gear: '4',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 513, name: '620264', slot: 21,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 538, name: '620255', slot: 22,
          suction: { value: 64, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 900,
          gear: '4',
          rate: { value: 3.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 55, warning: 0 },
          hasSand: false
        },
        { id: 660, name: '620522', slot: 23,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 200,
          gear: 'N',
          rate: { value: 5.3, tripped: true, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 350, name: '620485', slot: 24,
          suction: { value: 66, total: 100 },
          hhp: { value: 2750, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '5',
          rate: { value: 4.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 22 },
          hasSand: false
        },
        { id: 354, name: '620588', slot: 25,
          suction: { value: 65, total: 100 },
          hhp: { value: 600, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 750,
          gear: '2',
          rate: { value: 2.9, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 357, name: '620576', slot: 26, empty: true },
        { id: 122, name: '620133', slot: 27, empty: true },
        { id: 123, name: '620134', slot: 28, empty: true },
        { id: 358, name: '620506', slot: 29, empty: true },
        { id: 359, name: '620494', slot: 30, empty: true },
        { id: 360, name: '620596', slot: 31,
          suction: { value: 63, total: 100 },
          hhp: { value: 2145, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '5',
          rate: { value: 4.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 361, name: '620510', slot: 32,
          suction: { value: 64, total: 100 },
          hhp: { value: 800, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 800,
          gear: '3',
          rate: { value: 3.2, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 363, name: '620506', slot: 33,
          suction: { value: 65, total: 100 },
          hhp: { value: 1200, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 600,
          gear: '2',
          rate: { value: 4.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 3, warning: 0 },
          hasSand: false
        },
        { id: 129, name: '620129', slot: 34,
          suction: { value: 10, total: 100 },
          hhp: { value: 12, total: 3000 },
          discharge: { value: 15, total: 15000 },
          rpm: 200,
          gear: 'N',
          rate: { value: 0.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 1, warning: 10 },
          hasSand: false
        },
        { id: 396, name: '620497', slot: 35,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 0, total: 15000 },
          rpm: 0,
          gear: null,
          rate: { value: null, tripped: false, idle: false, stopped: true },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 418, name: '620598', slot: 36,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 400,
          gear: 'N',
          rate: { value: 4.0, tripped: false, idle: true, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 132, name: '620332', slot: 37,
          suction: { value: 64, total: 100 },
          hhp: { value: 0, total: 3000 },
          discharge: { value: 0, total: 15000 },
          rpm: 0,
          gear: null,
          rate: { value: null, tripped: false, idle: false, stopped: true },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 419, name: '620289', slot: 38,
          suction: { value: 65, total: 100 },
          hhp: { value: 725, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '2',
          rate: { value: 3.5, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
        { id: 420, name: '620288', slot: 39,
          suction: { value: 65, total: 100 },
          hhp: { value: 3000, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1100,
          gear: '6',
          rate: { value: 5.0, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 7 },
          hasSand: false
        },
        { id: 505, name: '620753', slot: 40,
          suction: { value: 64, total: 100 },
          hhp: { value: 2500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1300,
          gear: '4',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 },
          hasSand: false
        },
      ]
    },
    componentSimData: {
      blender1: { id:111, name: '720111',
        components: [
          { displayName: 'Discharge Engine', currentLife: 15000, expectedLife: 17000 },
          { displayName: 'Hydraulic Engine', currentLife: 18000, expectedLife: 17000 },
          { displayName: 'Cotta Box', currentLife: 3845, expectedLife: 7500 },
          { displayName: 'Slurry Pump', currentLife: 10000, expectedLife: 13000 },
          { displayName: 'Disc. Manifold', currentLife: 176, expectedLife: 1500 },
        ],
        alarmCounts: { critical: 0, warning: 0 }
      },
      blender2: { id:112, name: '720112',
        components: [
          { displayName: 'Discharge Engine', currentLife: 15000, expectedLife: 17000 },
          { displayName: 'Hydraulic Engine', currentLife: 13000, expectedLife: 17000 },
          { displayName: 'Cotta Box', currentLife: 6500, expectedLife: 7500 },
          { displayName: 'Slurry Pump', currentLife: 10000, expectedLife: 13000 },
          { displayName: 'Disc. Manifold', currentLife: 176, expectedLife: 1500 },
        ],
        alarmCounts: { critical: 23, warning: 0 }
      },
      hydration: { id:113, name: '720113',
        components: [
          { displayName: 'Engine', currentLife: 10000, expectedLife: 13000 }
        ],
        alarmCounts: { critical: 0, warning: 2 }
      },
      chemvan: { id:114, name: '920114',
        components: [
          { displayName: 'Engine', currentLife: 18000, expectedLife: 17000 }
        ],
        alarmCounts: { critical: 4, warning: 0 }
      },
      datavan: { id:115, name: '720115', alarmCounts: { critical: 4, warning: 0 } },
      pumps: [
        { id: 116, name: '620116', slot: 1,
          components: [
            { displayName: 'Engine', currentLife: -12000, expectedLife: 0 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 8000, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 117, name: '620117', slot: 2,
          components: [
            { displayName: 'Engine', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 77, warning: 0 }
        },
        { id: 118, name: '620118', slot: 3,
          components: [
            { displayName: 'Engine', currentLife: 11000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 119, name: '620119', slot: 4,
          components: [
            { displayName: 'Engine', currentLife: 9000, expectedLife: null },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 120, name: '620120', slot: 5,
          components: [
            { displayName: 'Engine', currentLife: null, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 8000, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 121, name: '620121', slot: 6,
          components: [
            { displayName: 'Engine', currentLife: -15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 122, name: '620122', slot: 7,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 123, name: '620123', slot: 8,
          components: [
            { displayName: 'Engine', currentLife: 12000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 124, name: '620124', slot: 9,
          components: [
            { displayName: 'Engine', currentLife: 11000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 125, name: '620125', slot: 10,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 126, name: '620126', slot: 11,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 127, name: '620127', slot: 12,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 127, name: '620128', slot: 13, empty: true},
        { id: 129, name: '620129', slot: 14,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 150, name: '620130', slot: 15,
          components: [
            { displayName: 'Engine', currentLife: 12000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 151, name: '620131', slot: 16,
          components: [
            { displayName: 'Engine', currentLife: 11000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 152, name: '620132', slot: 17,
          components: [
            { displayName: 'Engine', currentLife: 10000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 153, name: '620133', slot: 18,
          components: [
            { displayName: 'Engine', currentLife: 10000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 154, name: '620134', slot: 19,
          components: [
            { displayName: 'Engine', currentLife: 8000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 83 }
        },
        { id: 155, name: '620135', slot: 20,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 156, name: '620116', slot: 21,
          components: [
            { displayName: 'Engine', currentLife: -12000, expectedLife: 0 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 8000, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 157, name: '620117', slot: 22,
          components: [
            { displayName: 'Engine', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 77, warning: 0 }
        },
        { id: 158, name: '620118', slot: 23,
          components: [
            { displayName: 'Engine', currentLife: 11000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 159, name: '620119', slot: 24,
          components: [
            { displayName: 'Engine', currentLife: 9000, expectedLife: null },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 160, name: '620120', slot: 25,
          components: [
            { displayName: 'Engine', currentLife: null, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 8000, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 161, name: '620121', slot: 26,
          components: [
            { displayName: 'Engine', currentLife: -15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 162, name: '620122', slot: 27,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 163, name: '620123', slot: 28,
          components: [
            { displayName: 'Engine', currentLife: 12000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 164, name: '620124', slot: 29,
          components: [
            { displayName: 'Engine', currentLife: 11000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 165, name: '620125', slot: 30,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 166, name: '620126', slot: 31,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 167, name: '620127', slot: 32,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 127, name: '620128', slot: 33, empty: true},
        { id: 129, name: '620129', slot: 34, empty: true},
        { id: 130, name: '620130', slot: 35,
          components: [
            { displayName: 'Engine', currentLife: 12000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 171, name: '620131', slot: 36,
          components: [
            { displayName: 'Engine', currentLife: 11000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 172, name: '620132', slot: 37,
          components: [
            { displayName: 'Engine', currentLife: 10000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 133, name: '620133', slot: 38, empty: true },
        { id: 134, name: '620134', slot: 39,
          components: [
            { displayName: 'Engine', currentLife: 8000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 83 }
        },
        { id: 175, name: '620135', slot: 40,
          components: [
            { displayName: 'Engine', currentLife: 15000, expectedLife: 17000 },
            { displayName: 'Transmission', currentLife: 13000, expectedLife: 17000 },
            { displayName: 'Power End', currentLife: 5500, expectedLife: 7500 },
            { displayName: 'Fluid End', currentLife: 10000, expectedLife: 13000 },
          ],
          alarmCounts: { critical: 0, warning: 0 }
        },
      ]
    },
    healthSimData: {
      blender1: { id:111, name: '720111', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      blender2: { id:112, name: '720112', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      hydration: { id:113, name: '720113', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      chemvan: { id:114, name: '920114', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      datavan: { id:115, name: '720115', alarmCounts: { critical: 12, warning: 33, info: 44 } },
      pumps: [
        { id: 116, name: '620116', slot: 1,
          powerEnd: 3.22,
          fluidEnd: 3.55,
          iron: 2.88,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 117, name: '620117', slot: 2,
          powerEnd: 1.88,
          fluidEnd: 7.44,
          iron: 2.81,
          alarmCounts: { critical: 34, warning: 0, info: 0 }
        },
        { id: 118, name: '620118', slot: 3,
          powerEnd: 3.22,
          fluidEnd: 8.74,
          iron: 4.82,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 119, name: '620119', slot: 4,
          powerEnd: 10.42,
          fluidEnd: 14.82,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 16, info: 0 }
        },
        { id: 120, name: '620120', slot: 5,
          powerEnd: 5.62,
          fluidEnd: 4.35,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 121, name: '620121', slot: 6,
          powerEnd: 5.65,
          fluidEnd: 4.37,
          iron: 4.81,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 122, name: '620122', slot: 7, empty: true },
        { id: 123, name: '620123', slot: 8,
          powerEnd: 98.99,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 124, name: '620124', slot: 9,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 125, name: '620125', slot: 10,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 126, name: '620126', slot: 11,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 127, name: '620127', slot: 12,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 127, name: '620128', slot: 13,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 129, name: '620129', slot: 14,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 130, name: '620130', slot: 15,
          powerEnd: 3.25,
          fluidEnd: null,
          iron: 4.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 131, name: '620131', slot: 16,
          powerEnd: 3.25,
          fluidEnd: 8.75,
          iron: 4.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 132, name: '620132', slot: 17,
          powerEnd: null,
          fluidEnd: null,
          iron: null,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 133, name: '620133', slot: 18,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 72, info: 99 }
        },
        { id: 134, name: '620134', slot: 19,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 135, name: '620135', slot: 20,
          powerEnd: 1.25,
          fluidEnd: 20.75,
          iron: 5.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 116, name: '620116', slot: 21,
          powerEnd: 3.22,
          fluidEnd: 3.55,
          iron: 2.88,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 117, name: '620117', slot: 22,
          powerEnd: 1.88,
          fluidEnd: 7.44,
          iron: 2.81,
          alarmCounts: { critical: 34, warning: 0, info: 0 }
        },
        { id: 118, name: '620118', slot: 23,
          powerEnd: 3.22,
          fluidEnd: 8.74,
          iron: 4.82,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 119, name: '620119', slot: 24,
          powerEnd: 10.42,
          fluidEnd: 14.82,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 16, info: 0 }
        },
        { id: 120, name: '620120', slot: 25,
          powerEnd: 5.62,
          fluidEnd: 4.35,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 121, name: '620121', slot: 26,
          powerEnd: 5.65,
          fluidEnd: 4.37,
          iron: 4.81,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 122, name: '620122', slot: 27, empty: true },
        { id: 123, name: '620123', slot: 28,
          powerEnd: 98.99,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 124, name: '620124', slot: 29,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 125, name: '620125', slot: 30, empty: true},
        { id: 126, name: '620126', slot: 31, empty: true},
        { id: 127, name: '620127', slot: 32,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 127, name: '620128', slot: 33,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 129, name: '620129', slot: 34,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 130, name: '620130', slot: 35,
          powerEnd: 3.25,
          fluidEnd: null,
          iron: 4.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 131, name: '620131', slot: 36,
          powerEnd: 3.25,
          fluidEnd: 8.75,
          iron: 4.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 132, name: '620132', slot: 37,
          powerEnd: null,
          fluidEnd: null,
          iron: null,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 133, name: '620133', slot: 38,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 72, info: 99 }
        },
        { id: 134, name: '620134', slot: 39,
          powerEnd: 3.25,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 135, name: '620135', slot: 40,
          powerEnd: 1.25,
          fluidEnd: 20.75,
          iron: 5.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
      ],
      missile: [
        {
          slot: 1,
          value: 2.8
        },
        {
          slot: 2,
          value: 2.8
        },
        {
          slot: 3,
          value: 4.8
        },
        {
          slot: 4,
          value: 2.8
        },
        {
          slot: 5,
          value: 2.8
        },
        {
          slot: 6,
          value: 2.8
        },
        {
          slot: 7,
          value: 2.8
        },
        {
          slot: 8,
          value: null
        },
        {
          slot: 9,
          value: 2.8
        },
        {
          slot: 10,
          value: 2.8
        },
        {
          slot: 11,
          value: 2.8
        },
        {
          slot: 12,
          value: 2.8
        },
        {
          slot: 13,
          value: 22.8
        },
        {
          slot: 14,
          value: 2.8
        },
        {
          slot: 15,
          value: 2.8
        },
        {
          slot: 16,
          value: 2.8
        },
        {
          slot: 17,
          value: 2.8
        },
        {
          slot: 18,
          value: 2.8
        },
        {
          slot: 19,
          value: 2.8
        },
        {
          slot: 20,
          value: 2.8
        },
        {
          slot: 21,
          value: 2.8
        },
        {
          slot: 22,
          value: 2.8
        },
        {
          slot: 23,
          value: 4.8
        },
        {
          slot: 24,
          value: 2.8
        },
        {
          slot: 25,
          value: 2.8
        },
        {
          slot: 26,
          value: 2.8
        },
        {
          slot: 27,
          value: 2.8
        },
        {
          slot: 28,
          value: null
        },
        {
          slot: 29,
          value: 2.8
        },
        {
          slot: 30,
          value: 2.8
        },
        {
          slot: 31,
          value: 2.8
        },
        {
          slot: 32,
          value: 2.8
        },
        {
          slot: 33,
          value: 22.8
        },
        {
          slot: 34,
          value: 2.8
        },
        {
          slot: 35,
          value: 2.8
        },
        {
          slot: 36,
          value: 2.8
        },
        {
          slot: 37,
          value: 2.8
        },
        {
          slot: 38,
          value: 2.8
        },
        {
          slot: 39,
          value: 2.8
        },
        {
          slot: 40,
          value: 2.8
        }
      ]
    }
  },
  {
    id:10,
    name:'WTX-2',
    operationSimData: {
      blender1: { id:222, name: '750222',
        density: { value: 7, total: 10 },
        discharge: { value: 175, total: 300 },
        rate: { value: 54.6, stopped: false, idle: false },
        alarmCounts: { critical: 0, warning: 2 }
      },
      // blender2: { id:223, name: '750223',
      //   density: { value: 1, total: 10 },
      //   discharge: { value: 10, total: 300 },
      //   rate: { value: null, stopped: true, idle: false },
      //   alarmCounts: { critical: 0, warning: 0 }
      // },
      hydration: { id:224, name: '750224', alarmCounts: { critical: 98, warning: 0 } },
      chemvan: { id:225, name: '950225', alarmCounts: { critical: 0, warning: 0 } },
      datavan: { id:226, name: '750226',
      },
      sand: (_.random(1,20) % 7 === 0 ? null : {
        id: 101200, name: '710017',
        cleanRate: (_.random(1,20) % 5 === 0 ? null : sandCleanRate), 
        massConc: (_.random(1,20) % 5 === 0 ? null : sandMassConc), 
        selectedMassFlow: (_.random(1,20) % 5 === 0 ? null : sandSelectedMassFlow) 
      }),
      wellHead: { flowRate: 52.8, pressure: 9586, density1: 99.3, density2: 99.2 },
      // fleet does not have sand data
      pumps: [
        { id: 227, name: '650227', slot: 1,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 228, name: '650228', slot: 2,
          suction: { value: 49, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 229, name: '650229', slot: 3,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 230, name: '650230', slot: 4,
          suction: { value: 23, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 231, name: '650231', slot: 5,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 232, name: '650232', slot: 6,
          suction: { value: 70, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 233, name: '650233', slot: 7,
          suction: { value: 80, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 234, name: '650234', slot: 8,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 235, name: '650235', slot: 9,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 236, name: '650236', slot: 10,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 237, name: '650237', slot: 11,
          suction: { value: 66, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 238, name: '650238', slot: 12,
          suction: { value: 67, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 239, name: '650239', slot: 13,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 240, name: '650240', slot: 14,
          suction: { value: 63, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 241, name: '650241', slot: 15,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 242, name: '650242', slot: 16,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 243, name: '650243', slot: 17,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 244, name: '650244', slot: 18,
          suction: { value: 66, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 245, name: '650245', slot: 19,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 246, name: '650246', slot: 20,
          suction: { value: 67, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 227, name: '650247', slot: 21,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 228, name: '650248', slot: 22,
          suction: { value: 49, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 229, name: '650249', slot: 23,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 230, name: '650250', slot: 24,
          suction: { value: 23, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 231, name: '650251', slot: 25,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 232, name: '650252', slot: 26,
          suction: { value: 70, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 233, name: '650253', slot: 27,
          suction: { value: 80, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 234, name: '650254', slot: 28,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 235, name: '650255', slot: 29,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 236, name: '650256', slot: 30,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 237, name: '650257', slot: 31,
          suction: { value: 66, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 238, name: '650258', slot: 32,
          suction: { value: 67, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 239, name: '650259', slot: 33,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 240, name: '650260', slot: 34,
          suction: { value: 63, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 241, name: '650261', slot: 35,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 242, name: '650262', slot: 36,
          suction: { value: 64, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 243, name: '650263', slot: 37,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 244, name: '650264', slot: 38,
          suction: { value: 66, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 245, name: '650265', slot: 39,
          suction: { value: 65, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
        { id: 246, name: '650266', slot: 40,
          suction: { value: 67, total: 100 },
          hhp: { value: 1500, total: 3000 },
          discharge: { value: 9500, total: 15000 },
          rpm: 1000,
          gear: '6',
          rate: { value: 5.3, tripped: false, idle: false, stopped: false },
          alarmCounts: { critical: 0, warning: 0 }
        },
      ]
    },
    componentSimData: {
      blender1: null,
      blender2: null,
      hydration: null,
      chemvan: null,
      datavan: null,
      pumps: []
    },
    healthSimData: {
      blender1: { id:222, name: '750222', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      blender2: { id:223, name: '750223', empty: true, alarmCounts: { critical: 0, warning: 0, info: 0 } },
      hydration: { id:224, name: '750224', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      chemvan: { id:225, name: '950225', alarmCounts: { critical: 0, warning: 0, info: 0 } },
      datavan: { id:226, name: '750226' },
      pumps: [
        { id: 227, name: '650227', slot: 1,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 228, name: '650228', slot: 2,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 229, name: '650229', slot: 3,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 230, name: '650230', slot: 4,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 231, name: '650231', slot: 5,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 232, name: '650232', slot: 6,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 233, name: '650233', slot: 7,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 234, name: '650234', slot: 8,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 235, name: '650235', slot: 9,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 236, name: '650236', slot: 10,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 237, name: '650237', slot: 11,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 238, name: '650238', slot: 12,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 239, name: '650239', slot: 13,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 240, name: '650240', slot: 14,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 241, name: '650241', slot: 15,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 242, name: '650242', slot: 16,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 243, name: '650243', slot: 17,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 244, name: '650244', slot: 18,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 245, name: '650245', slot: 19,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 246, name: '650246', slot: 20,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 227, name: '650227', slot: 21,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 228, name: '650228', slot: 22,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 229, name: '650229', slot: 23,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 230, name: '650230', slot: 24,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 231, name: '650231', slot: 25,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 232, name: '650232', slot: 26,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 233, name: '650233', slot: 27,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 234, name: '650234', slot: 28,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 235, name: '650235', slot: 29,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 236, name: '650236', slot: 30,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 237, name: '650237', slot: 31,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 238, name: '650238', slot: 32,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 239, name: '650239', slot: 33,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 240, name: '650240', slot: 34,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 241, name: '650241', slot: 35,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 242, name: '650242', slot: 36,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 243, name: '650243', slot: 37,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 244, name: '650244', slot: 38,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 245, name: '650245', slot: 39,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
        { id: 246, name: '650246', slot: 40,
          powerEnd: 3.29,
          fluidEnd: 3.55,
          iron: 2.85,
          alarmCounts: { critical: 0, warning: 0, info: 0 }
        },
      ],
      missile: [
        {
          slot: 1,
          value: 2.8
        },
        {
          slot: 2,
          value: 2.8
        },
        {
          slot: 3,
          value: 4.8
        },
        {
          slot: 4,
          value: 2.8
        },
        {
          slot: 5,
          value: 2.8
        },
        {
          slot: 6,
          value: 2.8
        },
        {
          slot: 7,
          value: 2.8
        },
        {
          slot: 9,
          value: 2.8
        },
        {
          slot: 10,
          value: 2.8
        },
        {
          slot: 11,
          value: 2.8
        },
        {
          slot: 12,
          value: 2.8
        },
        {
          slot: 13,
          value: 22.8
        },
        {
          slot: 14,
          value: 2.8
        },
        {
          slot: 15,
          value: 2.8
        },
        {
          slot: 16,
          value: 2.8
        },
        {
          slot: 17,
          value: 2.8
        },
        {
          slot: 18,
          value: 2.8
        },
        {
          slot: 19,
          value: 2.8
        },
        {
          slot: 20,
          value: 2.8
        },
        {
          slot: 21,
          value: 2.8
        },
        {
          slot: 22,
          value: 2.8
        },
        {
          slot: 23,
          value: 4.8
        },
        {
          slot: 24,
          value: 2.8
        },
        {
          slot: 25,
          value: 2.8
        },
        {
          slot: 26,
          value: 2.8
        },
        {
          slot: 27,
          value: 2.8
        },
        {
          slot: 28,
          value: null
        },
        {
          slot: 29,
          value: 2.8
        },
        {
          slot: 30,
          value: 2.8
        },
        {
          slot: 31,
          value: 2.8
        },
        {
          slot: 32,
          value: 2.8
        },
        {
          slot: 33,
          value: 22.8
        },
        {
          slot: 34,
          value: 2.8
        },
        {
          slot: 35,
          value: 2.8
        },
        {
          slot: 36,
          value: 2.8
        },
        {
          slot: 37,
          value: 2.8
        },
        {
          slot: 38,
          value: 2.8
        },
        {
          slot: 39,
          value: 2.8
        },
        {
          slot: 40,
          value: 2.8
        }
      ]
    }
  },
];


export {
  fetchFracFleetMapComponentDetails,
  fetchFracFleetMapHealthDetails,
  fetchFracFleetMapOperationDetails
}
