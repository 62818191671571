import _ from 'lodash';
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Carousel from 'react-material-ui-carousel'
import { Typography, Box } from '@mui/material';

import { mdtPalette } from '../../../common/styles/mdtPalette';
import fleetMapStyles from '../fleetMapStyles';
import BlankUnit from '../visuals/blankUnit';
import BlankDatavan from '../visuals/blankDatavan';
import BlankWellHead from '../visuals/blankWellHead';
import BlankSand from '../visuals/blankSand';

const styles = {
  ...fleetMapStyles,

  unitColumnContainer: {
    display: 'flex',
    flexFlow: 'row nowrap',
  },

  unitColumn: {
    paddingRight: 2,
  },

  unitIron: {
    display: 'flex',
    width: '125px',
    height: '60px',
    alignItems: 'center',
    justifyContent: 'center'
  },

  datavanRowContainer: {
    display: 'flex',
    // The row that contains the data van needs to be the same width as the container for the
    // front side units so it will align correctly. The data van renders itself centered below
    // the front side.
    width: '1394px',
    alignItems: 'center',
    justifyContent: 'center'
  },

  wellColumnContainer: {
    display: 'flex',
    // The row that contains the well needs to be the same height as the container for the
    // front side units so it will align correctly. The well renders itself centered to the
    // right of the front side.
    height: '616px',
    width: '100px',
    alignItems: 'center',
    justifyContent: 'center'
  },

  ironRowContainer: {
    display: 'flex',
    height: '60px',
    alignItems: 'center',
    justifyContent: 'center'
  },

  carouselContainer: {
    width: '76%',
    position: 'absolute', 
    top: '30px', 
    left: '321px',
  },

  carouselNavButtonWrapper: {
    top: '255px', 
    zIndex: 3,
  },

  carouselIndicatorContainer: {
    marginTop: '-10px'
  },

  carouselIndicatorButton: {
    color: 'background.paper',
  }
};

class FracFleetLayout extends Component {

  renderPump(slot, isTopRow) {
    if (!_.isNil(this.props.renderPump)) {
      return this.props.renderPump(slot, isTopRow);
    }
    return <BlankUnit/>;
  }

  renderBlender(blender) {
    if (!_.isNil(this.props.renderBlender)) {
      return this.props.renderBlender(blender);
    }
    return <BlankUnit/>;
  }

  renderHydration() {
    if (!_.isNil(this.props.renderHydration)) {
      return this.props.renderHydration();
    }
    return <BlankUnit/>;
  }

  renderChemvan() {
    if (!_.isNil(this.props.renderChemvan)) {
      return this.props.renderChemvan();
    }
    return <BlankUnit/>;
  }

  renderDatavan() {
    if (!_.isNil(this.props.renderDatavan)) {
      return this.props.renderDatavan();
    }
    return <BlankDatavan/>;
  }

  renderWellHead() {
    if (!_.isNil(this.props.renderWellHead)) {
      return this.props.renderWellHead();
    }
    return <BlankWellHead/>
  }

  renderSand() {
    if (!_.isNil(this.props.renderSand)) {
      return this.props.renderSand();
    }
    return <BlankSand />;
  }

  generatePumpsColumns(start, end) {
    let columns = [];
    for (let i=start; i<end; i++) {
      let topLabel = _.padStart(i + 1 + '', 2, '0');
      let bottomLabel = _.padStart(11 + i + '', 2, '0');
      columns.push({ top: {label: topLabel, slot: i + 1}, bottom: {label: bottomLabel, slot: 11 + i} });
    }
    return columns;
  }

  render() {
    let pumpColumnsPage1 = this.generatePumpsColumns(0, 10);
    let pumpColumnsPage2 = this.props.moreThan20Pumps ? this.generatePumpsColumns(20, 30) : [];
    let pumpColumnPages = this.props.moreThan20Pumps ? [pumpColumnsPage1, pumpColumnsPage2] : [pumpColumnsPage1];

    return (
        <div>
          <Box sx={styles.fleetMapLayout}>

            {/* Render the back side units */}
            <Box sx={{...styles.unitColumnContainer, position: 'absolute', top: '30px', left: '10px'}}>
              <Box sx={styles.unitColumn}>
                <Typography variant={'subtitle1'} sx={styles.unitMapLabel}>Blender 2</Typography>
                { this.renderBlender(2) }
                <Box sx={styles.unitIron}>
                  <svg height='60' width='10'><rect height='60' width='10'  fill='#424242' /></svg>
                </Box>
                { this.renderHydration() }
                <Typography variant={'subtitle1'} sx={styles.unitMapLabel}>Hydration</Typography>
              </Box>
              <Box sx={styles.unitColumn}>
                <Typography variant={'subtitle1'} sx={styles.unitMapLabel}>Blender 1</Typography>
                { this.renderBlender(1) }
                <Box sx={styles.unitIron}>
                  <svg height='60' width='10'><rect height='60' width='10'  fill='#424242' /></svg>
                </Box>
                { this.renderChemvan() }
                <Typography variant={'subtitle1'} sx={styles.unitMapLabel}>C6</Typography>
              </Box>
            </Box>

            {/* Render the horizontal iron for the back side */}
            <Box sx={{...styles.ironRowContainer, position: 'absolute', top: '308px', left: '68px'}}>
              <svg height='10' width='150'>
                <rect height='10' width='150'  fill='#424242' />
              </svg>
            </Box>

            {/* Render the front side units */}
            {/* For some reason, the styles won't apply on the Carousel (made for MUI v5) with MUI v5 
                So let's us a Box to contain the carousel */}
            <Box sx={styles.carouselContainer}>
              <Carousel fullHeightHover={false} animation={'slide'} interval={5000}
                        autoPlay={false}
                        navButtonsAlwaysInvisible={this.props.hasNoData || !this.props.moreThan20Pumps}
                        indicators={true && !this.props.hasNoData && this.props.moreThan20Pumps}
                        navButtonsWrapperProps={{style: {top: '255px', zIndex: 3}}}
                        indicatorContainerProps={{style: {marginTop: '-10px'}}}
                        indicatorIconButtonProps={{style: {color: mdtPalette().materialUI.palette.background.paper}}}
                        // To achieve the desired effect of the active indicator taking on the primary.main color
                        // We need to set the style here instead of using a class
                        // If we use a class, I suspect there is a bug where it's not overriding the color
                        // it gets from indicatorIconButtonProps 
                        // However, using a style here will achieve that override
                        activeIndicatorIconButtonProps={{style: {color: mdtPalette().materialUI.palette.primary.main} }}
                        >
                {
                  pumpColumnPages.map((pumpColumns, index1) => 
                  {
                    return (
                      <Box key={index1} sx={{...styles.unitColumnContainer, position: 'relative', left: 20}}>
                        {
                          pumpColumns.map((column, index2) => {
                            return (
                              <Box key={index2} sx={styles.unitColumn}>
                                <Typography variant={'subtitle1'} sx={styles.unitMapLabel}>{column.top.label}</Typography>
                                { this.renderPump(column.top.slot, true) }
                                <Box sx={styles.unitIron}>
                                  <svg height='60' width='10'><rect height='60' width='10'  fill='#9E9E9E' /></svg>
                                </Box>
                                { this.renderPump(column.bottom.slot, false) }
                                <Typography variant={'subtitle1'} sx={styles.unitMapLabel}>{column.bottom.label}</Typography>
                              </Box>
                            )
                          })
                        }
                      </Box>
                    )
                  })
                }
              </Carousel>
            </Box>

            {/* Render the horizontal iron for the front side */}
            <Box sx={{...styles.ironRowContainer, position: 'absolute', top: '308px', left: '409px'}}>
              <svg height='10' width='1400'>
                <rect height='10' width='1400' fill='#9E9E9E' />
              </svg>
            </Box>


            {/* Render the data van unit below the front side units */}
            <Box sx={{...styles.datavanRowContainer, position: 'absolute', top: '665px', left: '342px'}}>
              <Box>
                { this.renderDatavan() }
                <Typography variant={'subtitle1'} sx={styles.datavanMapLabel}>Datavan</Typography>
              </Box>
              <Box sx={{marginLeft: '16px', display: 'flex', flexFlow: 'column nowrap', alignItems: 'center'}}>
                { this.renderSand() }
                <Typography variant={'subtitle1'} sx={styles.sandMapLabel}>Sand</Typography>
              </Box>
            </Box>

            {/* Render the well to the right of the front side units */}
            <Box sx={{...styles.wellColumnContainer, position: 'absolute', top: '30px', left: '1770px'}}>
              { this.renderWellHead() }
            </Box>
          </Box>
        </div>
    );

  }

}

FracFleetLayout.propTypes = {

  // Actions
  renderPump: PropTypes.func,
  renderBlender: PropTypes.func,
  renderHydration: PropTypes.func,
  renderChemvan: PropTypes.func,
  renderDatavan: PropTypes.func,
  renderWellHead: PropTypes.func,
  hasNoData: PropTypes.bool,
  moreThan20Pumps: PropTypes.bool
};

export default FracFleetLayout;